import React from "react"

const CartProgressBar = ({ subTotal, setIsOpenCart, enableGiftCard, lineItems }) => {
  const isFreeShipping = subTotal && subTotal >= 75
  const isFreeItem = subTotal && subTotal >= 35

  const priceTillFreeShipping = (Number(75) - Number(subTotal)).toFixed(2)

  const priceTillFreeItem = subTotal > 0 ? (Number(35) - Number(subTotal)).toFixed(2) : 35

  const percentageTillFreeShipping = isFreeItem ? 100 - (priceTillFreeShipping / 75) * 100 : 0

  const percentageTillFreeItem = isFreeItem ? "100" : 100 - (priceTillFreeItem / 35) * 100

  console.log("percentageTillFreeGiftCard - ", percentageTillFreeItem)

  return (
    <div className="cart-panel-header">
      <button className="close-btn" onClick={() => setIsOpenCart(false)}>
        <i className="h-icon-cancel"></i>
      </button>
      <h3 className="cart-title">{`My Cart (${lineItems?.length} ${
        lineItems?.length > 1 ? "Products" : "Product"
      })`}</h3>
      <p className="tag-text">
        {enableGiftCard &&
          (isFreeItem ? (
            <p>
              You&apos;ve got <span className="text-green">Free tropical bag</span>
            </p>
          ) : (
            <p>
              You are <span className="text-green">${priceTillFreeItem}</span> away from free tropical bag!
            </p>
          ))}

        {!isFreeShipping ? (
          <>
            <p>
              You are <span className="text-green">${priceTillFreeShipping}</span> away from free shipping!
            </p>
          </>
        ) : (
          <p>
            You&apos;ve got <span className="text-green">Free shipping</span>
          </p>
        )}
      </p>
      <div className="progress-bars">
        {enableGiftCard ? (
          <div className="progress-bar-wrapper">
            <div className="custom-progress-bar">
              <div className="green-bar" style={{ width: `${percentageTillFreeItem}%` }}></div>
            </div>
            <span className={`sub-text${isFreeItem ? " active" : ""}`}>
              <i className="icon-check xxs"></i> {"Free Tropical Bag"}
            </span>
          </div>
        ) : (
          " "
        )}

        <div className="progress-bar-wrapper">
          <div className="custom-progress-bar">
            <div className="green-bar" style={{ width: `${percentageTillFreeShipping}%` }}></div>
          </div>
          <span className={`sub-text${isFreeShipping ? " active" : ""}`}>
            <i className="icon-check xxs"></i> {"Free shipping"}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CartProgressBar
