import React from "react"
import { Link } from "gatsby"
import {
  frWrapper,
  frContainer,
  closeBtn,
  lastNote,
  lastNoteLink,
  clockItem,
  clockWrap,
  firstNote,
  timeIndicator,
} from "./blackFriday.module.scss"
import useWindowSize from "hooks/useWindowSize"
import useBlackFriday from "hooks/useBlackFriday"

const BlackFriday = ({ banner, countDown, setIsBlackFri }) => {
  const { countdownLeftText, countdownLeftLink, countdownRightText, countdownRightLink } = banner
  const { isMobile } = useWindowSize()
  const { countdownBgColor } = useBlackFriday()

  return (
    <div className={frWrapper} style={{ backgroundColor: countdownBgColor?.hex }}>
      <div className={frContainer}>
        <div className={firstNote}>
          <span>{countdownLeftText}</span>
          {isMobile && !countdownRightLink && <span>{countdownRightText}</span>}
          {isMobile && countdownRightLink && (
            <Link className={lastNoteLink} to={countdownRightLink}>
              {countdownRightText}
            </Link>
          )}
        </div>
        <div className={clockWrap}>
          <div className={clockItem}>
            <span>{countDown?.days}</span>
            <span className={timeIndicator}>DAYS</span>
          </div>
          <div className={clockItem}>
            <span>{countDown?.hours}</span>
            <span className={timeIndicator}>HRS</span>
          </div>
          <div className={clockItem}>
            <span>{countDown?.minutes}</span>
            <span className={timeIndicator}>MIN</span>
          </div>
          <div className={clockItem}>
            <span>{countDown?.seconds}</span>
            <span className={timeIndicator}>SEC</span>
          </div>
        </div>
        {!isMobile && !countdownRightLink && <div className={lastNote}>{countdownRightText}</div>}
        {!isMobile && countdownRightLink && (
          <Link className={lastNoteLink} to={countdownRightLink}>
            {countdownRightText}
          </Link>
        )}
        <div className={closeBtn} onClick={() => setIsBlackFri(false)}>
          <span>Close</span>
          <span>X</span>
        </div>
      </div>
    </div>
  )
}

export default BlackFriday
