import React, { useState, useContext, useEffect } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import CartOptionProduct from "components/molecules/CartOpionProduct/CartOptionProduct"

import { StoreContext } from "context/store"
import { round, currencyFormat } from "consts/helper"
import gtag, { gtagProductMapping } from "../../../utils/gtag"
import useBlackFriday from "hooks/useBlackFriday"
import CartCheckoutOption from "components/molecules/CartCheckoutOption/CartCheckoutOption"
import CartProduct from "components/molecules/CartModal/CartProduct/CartProduct"
import CartProgressBar from "components/molecules/CartModal/CartProgressBar/CartProgressBar"
import useProductsFlavorsShopiyVariants from "hooks/useProductsFlavorsShopiyVariants"

const CartModal = ({ headerHide }) => {
  const { enableDiscount } = useBlackFriday()
  const { allProducts, allFlavors, shopifyVariants } = useProductsFlavorsShopiyVariants()
  allProducts.nodes.forEach(product => {
    if (enableDiscount) {
      product.flavors.forEach(allowDiscountItem => {
        allowDiscountItem.allowDiscount = true
      })
    } else {
      product.flavors.forEach(allowDiscountItem => {
        allowDiscountItem.allowDiscount = false
      })
    }
  })

  allFlavors.nodes.forEach(flavor => {
    if (enableDiscount) {
      flavor.allowDiscount = true
    } else {
      flavor.allowDiscount = false
    }
  })

  const { loading, addToCart, removeFromCart, lineItems, isOpenCart, setIsOpenCart, handleCheckout, enableGiftCard } =
    useContext(StoreContext)

  const [subTotal, setSubTotal] = useState(null)
  const [addedGift, setAddedGift] = useState(false)

  useEffect(() => {
    let tempSubTotal = 0
    lineItems.map(
      item =>
        (tempSubTotal += item.kmkAllowDiscount
          ? Number(item.kmkPrice)
          : item.kmkIsSubscribe
          ? round((item.kmkPrice * item.quantity * (100 - item.kmkDiscount)) / 100, 2)
          : Number(item.kmkPrice * item.quantity))
    )
    setSubTotal(round(tempSubTotal, 2))
  }, [lineItems])

  // const addGiftCardToCart = () => {
  //   const giftCard = allProducts.nodes.filter(prod => prod.shopifyProductId === "4660844003415")[0]

  //   const inLineItem = {
  //     categories: [""],
  //     kmkIsSubscribe: false,
  //     kmkPrice: 0,
  //     kmkImage: giftCard?.flavors[0]?.images[0],
  //     kmkTitle: "Kiss My Keto Gift Card",
  //     kmkSku: "Gift Card",
  //     kmkQuantity: 1,
  //     kmkDiscount: null,
  //     charge_interval_frequency: null,
  //     fulfillment_service: "manual",
  //     order_interval_frequency: null,
  //     order_interval_unit: null,
  //     product_id: "4660844003415",
  //     quantity: 1,
  //     variant_id: "39397072011351",
  //     storefrontId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM5NzA3MjAxMTM1MQ==",
  //   }

  //   addToCart(inLineItem)
  //   setAddedGift(true)
  // }

  const addFreeItem = () => {
    const freeItem = allProducts.nodes.filter(prod => prod.shopifyProductId === "6817756414039")[0]

    const inLineItem = {
      categories: [""],
      kmkIsSubscribe: false,
      kmkPrice: 0,
      kmkImage: freeItem?.flavors[0]?.images[0],
      kmkTitle: "Tropical Rings",
      kmkSku: "GUMMIES-RINGS-1BAG",
      kmkQuantity: 1,
      kmkDiscount: null,
      charge_interval_frequency: null,
      fulfillment_service: "manual",
      order_interval_frequency: null,
      order_interval_unit: null,
      product_id: "6817756414039",
      quantity: 1,
      variant_id: "6817756414039",
      storefrontId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTk4MzM0MDQ1Mzk3NQ==",
    }

    addToCart(inLineItem)
    setAddedGift(true)
  }

  useEffect(() => {
    if (enableGiftCard) {
      const isExistGift = lineItems.filter(item => item.product_id === "6817756414039")
      if (subTotal) {
        if (!isExistGift?.length && subTotal >= 35 && !addedGift) {
          // add Gift Card from the cart
          addFreeItem()
        }
        if (subTotal < 35 && (addedGift || isExistGift?.length)) {
          // remove Gift Card from the cart
          const freeItem = lineItems.filter(item => item.variant_id === "6817756414039")[0]
          removeFromCart(freeItem)
          setAddedGift(false)
        }
      }
    }
  }, [subTotal])

  useEffect(() => {
    if (isOpenCart) {
      setTimeout(() => {
        let tempSubTotal = 0
        lineItems.map(item => (tempSubTotal += Number(item.kmkPrice)))
        gtag("dl_view_cart", {
          event_id: "view_cart_" + Math.floor(Date.now() / 1000),
          cart_total: round(tempSubTotal, 2),
          ecommerce: {
            currencyCode: "USD",
            actionField: {
              list: "Shopping Cart",
            },
            impressions: lineItems.map(gtagProductMapping),
          },
        })
      }, 1000)
    }
  }, [isOpenCart])

  return isOpenCart ? (
    <div className={`cart-side-drawer-wrapper${isOpenCart ? " active" : ""}`}>
      <div className={isOpenCart ? "backdrop" : ""} onClick={() => setIsOpenCart(false)}></div>
      <aside className={isOpenCart ? "cart-sidebar active" : "cart-sidebar"} id="cartSidebar">
        <div className="cart-panel">
          <CartProgressBar
            setIsOpenCart={setIsOpenCart}
            enableGiftCard={enableGiftCard}
            lineItems={lineItems}
            subTotal={subTotal}
          />
          <div className="cart-panel-body">
            <div className="prods-wraper">
              <div className="prod-cart-wrap">
                {lineItems?.map((product, idx) => (
                  <CartProduct
                    key={idx}
                    product={product}
                    shopifyVariants={shopifyVariants.nodes}
                    allFlavors={allFlavors}
                  />
                ))}
              </div>
            </div>
            {lineItems?.length > 0 && (
              <CartOptionProduct allProducts={allProducts} shopifyVariants={shopifyVariants.nodes} />
            )}
          </div>
          <div className="cart-panel-footer">
            <div className="chekout-row">
              <div className="col-left">
                <p className="sub-title">SUBTOTAL</p>
                <p className="sub-total">${currencyFormat(subTotal)}</p>
              </div>
              <div className="col-right">
                {loading ? (
                  <button className={`btn btn-primary btn-checkout btn-disabled`}>
                    <ClipLoader color={"white"} loading={true} size={30} />
                  </button>
                ) : (
                  <button
                    className={`btn btn-primary btn-checkout ${lineItems?.length === 0 ? "btn-disabled" : ""}`}
                    onClick={() => handleCheckout()}
                  >
                    <i className="h-icon-lock"></i>Proceed to Checkout
                  </button>
                )}
                <CartCheckoutOption />
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  ) : (
    ""
  )
}

export default CartModal
