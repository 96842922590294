import React, { useContext, useEffect, useState } from "react"
import Axios from "axios"
import { currencyFormat, round, SelectOptionFlavors, SelectOptionQty } from "consts/helper"
import Image from "components/atoms/Image"
import CustomSelect from "components/atoms/CustomSelect"
import { StoreContext } from "../../../context/store"

const CartOptionProduct = ({ allProducts, shopifyVariants }) => {
  const { addToCart, cartProds } = useContext(StoreContext)
  const [isOptionOpened, setIsOptionOpened] = useState(false)
  const [rebuyItem, setRebuyItem] = useState()
  const [selFlavor, setSelFlavor] = useState()
  const [qty, setQty] = useState(1)

  useEffect(() => {
    Axios({
      url: "https://rebuyengine.com/api/v1/products/recommended",
      params: {
        key: process.env.GATSBY_REBUY_API_KEY,
        limit: 1,
        shopify_product_ids: handleProdIds(),
      },
    })
      .then(response => {
        const rebuyId = response?.data?.data[0]?.id
        const rebuyItem = allProducts.nodes.filter(prod => prod.shopifyProductId == rebuyId)[0]
        rebuyItem.categories = [response?.data?.data[0]?.product_type]
        setRebuyItem(rebuyItem)
        setSelFlavor(rebuyItem?.flavors[0])
      })
      .catch(error => {
        console.log(error)
      })
  }, [cartProds])

  const handleProdIds = () => {
    if (cartProds?.length) {
      let ids = ""
      cartProds.map((prod, idx) => {
        ids += `${prod}${idx + 1 !== cartProds.length ? "," : ""}`
      })
      return ids
    } else {
      return ""
    }
  }

  const handlePrice = () => {
    const discountedPrice = round(((selFlavor?.price * (100 - (qty - 1) * 3)) / 100) * qty, 2)
    if (!selFlavor?.allowDiscount) {
      return round(selFlavor?.price * qty, 2)
    }
    return discountedPrice
  }

  const handleFlavor = event => {
    const tempSelFlavor = rebuyItem.flavors.filter(
      flavor => flavor.flavor === event.label && flavor.sku === event.value
    )
    tempSelFlavor.length && setSelFlavor(tempSelFlavor[0])
  }

  const handleAddToCart = () => {
    let correctSku = ""
    if (selFlavor.allowDiscount) {
      correctSku = `${selFlavor.sku}${qty > 1 ? `-${qty}` : ""}`
    } else {
      correctSku = selFlavor.sku
    }

    const selVariant = shopifyVariants.filter(variant => variant.sku === correctSku)[0]

    const inLineItem = {
      categories: rebuyItem.categories,
      kmkIsSubscribe: false,
      kmkPrice: selVariant.price,
      kmkImage: selFlavor.images[0],
      kmkTitle: selVariant.title,
      kmkSku: selFlavor.sku,
      kmkQuantity: qty,
      kmkDiscount: rebuyItem._rawSubscriptionSettings.initialDiscount,
      kmkAllowDiscount: selFlavor?.allowDiscount,
      charge_interval_frequency: null,
      fulfillment_service: "manual",
      order_interval_frequency: null,
      order_interval_unit: null,
      product_id: rebuyItem.shopifyProductId,
      quantity: qty,
      variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
      storefrontId: selVariant.storefrontId,
      name: selFlavor.name,
      id: selFlavor._id,
    }
    addToCart(inLineItem)
    setIsOptionOpened(false)
  }

  return rebuyItem && selFlavor ? (
    <div className="prod-option-wrap">
      <h4 className="title-text">YOUR ORDER GOES GREAT WITH</h4>
      <div className="row-col">
        <div className="col-left">
          <div className="img-box">
            {selFlavor?.images?.length > 0 && <Image image={selFlavor?.images[0]?.asset} />}
          </div>
        </div>
        <div className="col-middle">
          <p className="pd-title">{rebuyItem.name}</p>
          {!isOptionOpened && (
            <button className="btn btn-primary btn-option" onClick={() => setIsOptionOpened(!isOptionOpened)}>
              Options
            </button>
          )}
        </div>
        <div className="col-right">
          <p className="pd-price">${currencyFormat(handlePrice())}</p>
        </div>
      </div>
      {isOptionOpened ? (
        <div className="add-to-order-wrapper">
          <div className="flavor-select">
            <CustomSelect
              options={SelectOptionFlavors(rebuyItem?.flavors)}
              placeholder={selFlavor.flavor}
              onChange={handleFlavor}
            />
          </div>
          <div className="add-to-order-action-with-qty">
            <div className="qty-select">
              <CustomSelect
                options={SelectOptionQty(selFlavor?.unit, selFlavor?.allowDiscount)}
                onChange={qty => setQty(qty.value)}
                placeholder="1"
              />
            </div>
            <button className="btn btn-primary btn-add-to-order" onClick={handleAddToCart}>
              Add To Order
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  )
}

export default CartOptionProduct
