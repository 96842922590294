import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StoreContext } from "context/store"
import Header from "../Header"
import Footer from "../Footer"
import "assets/styles/main.scss"
import useBlackFriday from "../../../hooks/useBlackFriday"
import { useZendeskHidePages } from "hooks/useZendesk"
import useUtmDataStore from "hooks/useUtmDataStore"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const blFridayData = useBlackFriday()
  const { isBlackFri } = useContext(StoreContext)
  const [hideNav, setHideNav] = useState(false)
  useZendeskHidePages()

  if (typeof window !== "undefined" && document) {
    useUtmDataStore()
  }

  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        style={{ background: "#f7f7f7", color: "black", borderTop: "1px solid #d5d5d5" }}
        buttonStyle={{ backgroundColor: "#ff3513", color: "white", fontSize: "13px" }}
        expires={150}
      >
        We use cookies to enhance your browsing experience and provide personalized content. By continuing to use our
        website, you consent to the use of cookies in accordance with our{" "}
        <a href="https://shop.kissmyketo.com/pages/cookie-policy" style={{ color: "#ff3513" }}>
          Cookie Policy
        </a>
        .
      </CookieConsent>
      <Header banner={blFridayData} setHideNav={setHideNav} />
      <main className={`main-wrapper ${isBlackFri ? "main-fri-wrapper" : ""} ${hideNav ? "hideNav" : ""}`}>
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
