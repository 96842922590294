import React from "react"
import Select, { components } from "react-select"
import ArrowIcon from "assets/images/icons-arrow.svg"

const colourStyles = {
  control: styles => ({
    ...styles,
    height: "50px",
    boxShadow: "none",
    border: "1px solid #000",
    borderRadius: 0,
    "&:hover": {
      border: "1px solid black",
    },
    cursor: "pointer",
  }),
  menuList: styles => ({
    ...styles,
    padding: 0,
    maxHeight: "220px",
    overflow: "auto",
  }),
  menu: styles => ({
    ...styles,
    border: "1px solid",
    borderRadius: 0,
    padding: 0,
    minWidth: "252px",
    minHeight: "0",
    width: "100%",
  }),
  option: styles => {
    return {
      ...styles,
      backgroundColor: "#fff",
      padding: "0",
      margin: 0,
      cursor: "pointer",
    }
  },
  indicatorContainer: styles => ({
    ...styles,
    marginRight: "6px",
  }),
  placeholder: styles => ({
    ...styles,
    color: "#000000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
}

const Option = ({ children, ...props }) => {
  return props.isSelected ? (
    <components.Option {...props}>
      <div className="option-selected">
        {props.data.component || <div className="default-option">{props.data.label}</div>}
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>
      {props.data.component || <div className="default-option">{props.data.label}</div>}
    </components.Option>
  )
}

const SingleValue = ({ children, ...props }) => {
  return <components.SingleValue {...props}>{children}</components.SingleValue>
}

const IndicatorSeparator = () => {
  return ""
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img className="select-arrow" src={ArrowIcon} alt="Arrow" />
    </components.DropdownIndicator>
  )
}

const CustomSelect = ({ options, placeholder, classNames = [], noDropdown, onChange, ...props }) => {
  return (
    <Select
      isSearchable={false}
      placeholder={placeholder}
      className={"custom-select " + classNames}
      IndicatorSeparator={false}
      styles={colourStyles}
      options={options}
      components={{
        Option,
        SingleValue,
        IndicatorSeparator,
        DropdownIndicator: noDropdown ? null : DropdownIndicator,
      }}
      onChange={onChange}
      {...props}
    />
  )
}

export default CustomSelect
