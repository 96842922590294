import React from "react"

const BannerText = ({ isBlackFriday, bannerText }) => {
  return (
    <>
      {!isBlackFriday && (
        <div>
          <div className="header-top">
            <div className="container">
              <p className="text-cnt text-center">{bannerText}</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BannerText
