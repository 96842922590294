import React, { useEffect } from "react"

const useUtmDataStore = () => {
  const storeUTMData = utmData => {
    localStorage.setItem("trackingParams", utmData)
  }

  useEffect(() => {
    const params = window?.location?.search

    // 1. On the first visit save any utms and click ids present in the url to local storage.

    // 2. On subsequent visits, if the user has new utms or a new gclid, clear the existing utms and gclid and
    // replace them with whatever is present.

    if (params.includes("utm") || params.includes("gclid")) {
      storeUTMData(params)
    }

    if (params.includes("fbclid") || params.includes("ttclid") || params.includes("irclickid")) {
      if (params.includes("utm")) {
        // 4. If in a subsequent visit a click id other than the gclid is present (fbclid, ttclid)
        // AND new utm values ARE present clear the utm values and gclid, and save the new click id and utms.
        storeUTMData(params)
      } else {
        // 3. If in a subsequent visit a click id other than the gclid is present (fbclid, ttclid, ...)
        // without accompanying utms, save this value without making changes to the existing parameters.
        const storedParams = JSON.parse(localStorage.getItem("trackingParams"))
        const newParams = storedParams + params.replace("?", "&")
        storeUTMData(newParams)
      }
    }
  }, [window?.location?.pathname])
}

export default useUtmDataStore
