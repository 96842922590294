import React, { useContext } from "react"
import { currencyFormat, round } from "consts/helper"
import Image from "components/atoms/Image"
import { StoreContext } from "../../../../context/store"

const CartProduct = ({ product, shopifyVariants, allFlavors }) => {
  const {
    kmkIsSubscribe,
    kmkPrice,
    kmkImage,
    kmkTitle,
    kmkSku,
    kmkDiscount,
    kmkQuantity,
    kmkAllowDiscount,
    charge_interval_frequency,
    order_interval_unit,
    variant_id,
    quantity,
  } = product
  const { removeFromCart, updateCart, loading } = useContext(StoreContext)

  let kmkQty = kmkQuantity

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      removeFromCart(product)
      return
    }

    if (kmkAllowDiscount) {
      const isExisting = shopifyVariants.filter(variant => variant.sku === `${kmkSku}${value > 1 ? `-${value}` : ""}`)
      const selFlavor = allFlavors?.nodes?.filter(flavor => flavor.sku === kmkSku)[0]

      if (isExisting?.length) {
        updateCart(
          {
            ...product,
            kmkImage: selFlavor ? selFlavor.images[0] : kmkImage,
            kmkPrice: kmkIsSubscribe
              ? round((isExisting[0].price * (100 - kmkDiscount)) / 100, 2)
              : isExisting[0].price,
            kmkTitle: isExisting[0].title,
            kmkQuantity: value,
            variant_id: isExisting[0].shopifyId.replace("gid://shopify/ProductVariant/", ""),
            storefrontId: isExisting[0].storefrontId,
          },
          variant_id
        )
        kmkQty = value
      }

      if (!isExisting?.length && !kmkDiscount) {
        updateCart(
          {
            ...product,
            kmkPrice: kmkPrice * value,
            kmkQuantity: value,
            quantity: value,
          },
          variant_id
        )
        kmkQty = value
      }
    } else {
      updateCart(
        {
          ...product,
          kmkQuantity: value,
          quantity: value,
        },
        variant_id
      )
      kmkQty = value
    }
  }

  let updatedPrice = kmkPrice

  if (!kmkAllowDiscount) {
    updatedPrice = kmkIsSubscribe ? round((kmkPrice * quantity * (100 - kmkDiscount)) / 100, 2) : kmkPrice * quantity
  }

  return (
    <>
      <div className="prod-item-row">
        <div className="col-left">
          <div className="prod-img">{kmkImage ? <Image image={kmkImage.asset} /> : null}</div>
        </div>
        <div className="col-right">
          <div className="prod-title-wrapper">
            <div className="prod-title">{kmkTitle}</div>
            <button
              className={`close-btn ${loading ? "btn-disabled" : ""}`}
              id="removeItem"
              onClick={() => removeFromCart(product)}
            >
              <i className="h-icon-cancel"></i>
            </button>
          </div>
          <div className="input-wrapper">
            <div className="input-group input-qty">
              <span className="input-group-btn">
                <button
                  type="button"
                  className="quantity-left-minus btn  btn-number"
                  data-type="minus"
                  data-field=""
                  disabled={kmkSku === "Gift Card"}
                  onClick={() => handleQuantityChange(Number(kmkQty || 0) - 1)}
                >
                  <span>&#8722;</span>
                </button>
              </span>
              <div className="product-qty">{quantity}</div>
              <span className="input-group-btn">
                <button
                  type="button"
                  className="quantity-right-plus btn btn-number"
                  data-type="plus"
                  data-field=""
                  disabled={kmkSku === "Gift Card"}
                  onClick={() => handleQuantityChange(Number(kmkQty || 0) + 1)}
                >
                  <span>&#43;</span>
                </button>
              </span>
            </div>
            <div className="pd-price-wrap">
              <p className="pd-price">${currencyFormat(updatedPrice)}</p>
            </div>
          </div>
        </div>
      </div>

      {charge_interval_frequency && order_interval_unit && (
        <div className="subscription-text">Every {charge_interval_frequency} Days</div>
      )}
    </>
  )
}

export default CartProduct
