import { Link } from "gatsby"
import { default as React } from "react"
import { connectStateResults, Highlight, Hits, Index, Snippet, PoweredBy } from "react-instantsearch-dom"
import styled, { css } from "styled-components"

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return (
    <span className="HitCount">
      {hitCount} result{hitCount > 1 ? `s` : ``}
    </span>
  )
})
const PageHit = ({ hit }) => {
  const targetLink = hit.slug?.current || hit.permalink
  const isExternal = targetLink.includes("https")
  return (
    <div>
      {isExternal ? (
        <a href={targetLink} target={hit.permalink && "_blank"}>
          <Highlight attribute="title" hit={hit} tagName="mark" />
          <Highlight attribute="name" hit={hit} tagName="mark" />
          <Highlight attribute="post_title" hit={hit} tagName="mark" />
        </a>
      ) : (
        <Link to={hit.slug?.current || hit.permalink} target={hit.permalink && "_blank"}>
          <Highlight attribute="title" hit={hit} tagName="mark" />
          <Highlight attribute="name" hit={hit} tagName="mark" />
          <Highlight attribute="post_title" hit={hit} tagName="mark" />
        </Link>
      )}
      <Snippet attribute="excerpt" hit={hit} tagName="mark" />
    </div>
  )
}
const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <div className="Indice">
      {index.title}
      <HitCount />
    </div>
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)
const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)
const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  width: 80vw;
  max-width: 100%;
  box-shadow: 0 0 5px 0;
  padding: 1em;
  border-radius: 2px;
  background: ${({ theme }) => theme.background};
`
export default styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}
  .Indice {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8em;
    color: #888;
  }
  .HitCount {
    float: right;
  }
  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
    }
    li.ais-Hits-item {
      margin-bottom: 1em;
      a {
        color: ${({ theme }) => theme.foreground};
        h4 {
          margin-bottom: 0.2em;
        }
      }
    }
  }
  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;
    svg {
      width: 70px;
    }
  }
`
