import React, { useState } from "react"
import { Link } from "gatsby"

const MenuLinksGroup = ({ linkTitle, path, links, subMenuOpen, setSubMenuOpen }) => {
  let node = ""

  // When only link
  if (path) {
    node = (
      <li className="mob-item">
        {path.includes("https") || path.includes("http") ? (
          <a href={path} className="mob-link">
            {linkTitle}
          </a>
        ) : (
          <Link to={path} className="mob-link">
            {linkTitle}
          </Link>
        )}
      </li>
    )
  }
  // When it does has child links
  else {
    node = (
      <li className={subMenuOpen === linkTitle ? "mob-item has-child active" : "mob-item has-child"}>
        <div className="mob-link" onClick={() => setSubMenuOpen(subMenuOpen === linkTitle ? "" : linkTitle)}>
          <span className="link-plus-minus-icon" />
          {linkTitle}
        </div>

        <ul className="mob-submenu">
          {links &&
            links.map((link, index) =>
              link.path.includes("https") || link.path.includes("http") ? (
                <a href={link.path} className="mob-link" key={index}>
                  {link.text}
                </a>
              ) : (
                <Link to={link.path} className="mob-link" key={index}>
                  {link.text}
                </Link>
              )
            )}
        </ul>
      </li>
    )
  }

  return node
}

const HeaderMobileLeftMenu = ({ open, onClose, categories }) => {
  const MobileHeaderLeftMenuData = [
    {
      linkTitle: "Shop",
      links: [
        {
          text: "Shop All",
          path: "/all/",
        },
        ...categories.map(category => ({
          text: category.title,
          path: category.slug,
        })),
      ],
    },
    {
      linkTitle: "Us vs Them",
      path: "/us-vs-them/",
    },
    {
      linkTitle: "Reviews",
      path: "/reviews/",
    },
    {
      linkTitle: "Refer a Friend",
      path: "https://shop.kissmyketo.com/pages/refer",
    },
    {
      linkTitle: "Our Story",
      path: "/our-story/",
    },
    {
      linkTitle: "Contact",
      links: [
        {
          text: "Help Center",
          path: "https://help.kissmyketo.com/hc/en-us/",
        },
        {
          text: `Text Us: KMKHELP to
+1 (507) 216-0328`,
          path: "#",
        },
        {
          text: "Chat",
          path: "#",
        },
        {
          text: "Email",
          path: "#",
        },
        {
          text: "Store Locator",
          path: "https://shop.kissmyketo.com/pages/store-locator",
        },
      ],
    },
    {
      linkTitle: "Sign In",
      path: "https://shop.kissmyketo.com/account/login/",
    },
  ]
  const [subMenuOpen, setSubMenuOpen] = useState("Shop")

  return (
    <div className="mobile-side-drawer">
      <div className={open ? "backdrop" : ""} onClick={onClose}></div>
      <div className={open ? "mobile-menu active" : "mobile-menu"}>
        <button className="close-menu" onClick={onClose}>
          <i className="h-icon-cancel"></i>
        </button>

        <ul className="mobmenu-list">
          {MobileHeaderLeftMenuData.map((link, index) => {
            return (
              <MenuLinksGroup
                linkTitle={link.linkTitle}
                path={link.path}
                links={link.links}
                index={index}
                subMenuOpen={subMenuOpen}
                setSubMenuOpen={setSubMenuOpen}
                key={index}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default HeaderMobileLeftMenu
