import algoliasearch from "algoliasearch/lite"
import { createRef, default as React, useState, useMemo } from "react"
import { InstantSearch, RefinementList, SearchBox, Hits } from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import SearchInput from "components/atoms/SearchInput"
import SearchResult from "components/atoms/SearchResult"
const theme = {
  foreground: "#050505",
  background: "white",
  faded: "#888",
}
export default function Search({ indices }) {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () => algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY),
    []
  )

  return (
    <ThemeProvider theme={theme}>
      <div ref={rootRef}>
        <InstantSearch
          searchClient={searchClient}
          distinct={true}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <SearchInput onFocus={() => setFocus(true)} hasFocus={hasFocus} />
          <SearchResult show={query && query.length > 0 && hasFocus} indices={indices} />
        </InstantSearch>
      </div>
    </ThemeProvider>
  )
}
