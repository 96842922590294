// extracted by mini-css-extract-plugin
export var clockItem = "blackFriday-module--clockItem--V56Cy";
export var clockWrap = "blackFriday-module--clockWrap--KWGYz";
export var closeBtn = "blackFriday-module--closeBtn--Mg-Da";
export var firstNote = "blackFriday-module--firstNote--48Ciu";
export var firstNoteLink = "blackFriday-module--firstNoteLink--3rLQp";
export var frContainer = "blackFriday-module--frContainer--23uFM";
export var frWrapper = "blackFriday-module--frWrapper--pHRiE";
export var lastNote = "blackFriday-module--lastNote--jcQ9P";
export var lastNoteLink = "blackFriday-module--lastNoteLink--fkxDO";
export var timeIndicator = "blackFriday-module--timeIndicator--Sf5Vr";