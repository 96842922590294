import { useEffect } from "react"

export const useZendeskHidePages = () => {
  useEffect(() => {
    setTimeout(() => {
      window.zE && window.zE.hide()
    }, 1000)
  }, [])
}

export const useZendeskShow = () => {
  useEffect(() => {
    setTimeout(() => {
      window.zE && window.zE.show()
    }, 1000)
  }, [])
}

export const useZendeskHide = () => {
  useEffect(() => {
    setTimeout(() => {
      window.zE && window.zE.hide()
    }, 1000)
  }, [])
}
