import { graphql, useStaticQuery } from "gatsby"

const useProductsFlavorsShopiyVariants = () => {
  const { allProducts, allFlavors, shopifyVariants } = useStaticQuery(graphql`
    query RebuyProductQuery {
      allProducts: allSanityProduct {
        nodes {
          ...Product
        }
      }
      allFlavors: allSanityFlavor {
        nodes {
          sku
          images {
            _key
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              originalFilename
            }
          }
        }
      }
      shopifyVariants: allShopifyProductVariant {
        nodes {
          sku
          storefrontId
          shopifyId
          title
          price
        }
      }
    }
  `)
  return {
    allProducts,
    allFlavors,
    shopifyVariants,
  }
}

export default useProductsFlavorsShopiyVariants
