import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const CartCheckoutOption = () => {
  return (
    <div className="chekout-option">
      <div className="btn-group">
        <span className="btn-chk">
          <StaticImage src="../../../assets/images/payment/8.png" alt="G Pay" />
        </span>
        <span className="btn-chk">
          <StaticImage src="../../../assets/images/payment/9.png" alt="Apple Pay" />
        </span>
        <span className="btn-chk">
          <StaticImage src="../../../assets/images/payment/3.png" alt="AMEX" />
        </span>
        <span className="btn-chk">
          <StaticImage src="../../../assets/images/payment/2.png" alt="Master Card" />
        </span>
        <span className="btn-chk">
          <StaticImage src="../../../assets/images/payment/12.png" alt="PayPal Pay" />
        </span>
      </div>
      <p className="text-center">Questions? 855-KISSMYKETO</p>
    </div>
  )
}

export default CartCheckoutOption
